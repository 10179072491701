import React from 'react';
import { css } from 'styled-components';
import { AnimatedNfts } from 'components/animated-nfts';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { MainSection } from 'modules/main-section';

export const NftMainSection = () => {
    return (
        <MainSection
            icon={<AnimatedNfts />}
            iconWrapperCss={css`
                max-width: 33.125rem;
            `}
            textWrapperCss={css`
                color: var(--white-color);
            `}
            titleId="nft-subpage.nftBlockchainSolutions"
            paragraphs={['nft-subpage.unlockThePowerOf']}
            estimateProjectAnalytics={
                gaTrackedEvents.REAL_ESTATE.CTA.ESTIMATE_PROJECT
            }
            intoCallAnalytics={gaTrackedEvents.REAL_ESTATE.CTA.INTRO_CALL}
        />
    );
};
