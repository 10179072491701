import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { SeeAllCaseStudiesSection } from 'modules/see-all-case-studies-section';

import { useNftData } from './constant';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const NftCaseStudiesSection = () => {
    const { formatMessage } = useIntl();

    return (
        <SeeAllCaseStudiesSection
            title="caseStudies"
            description={formatMessage(
                {
                    id: 'nft-subpage.discoverTheImpact',
                },
                {
                    link: (chunks) => {
                        return (
                            <SPrefixLangLink to={PATHS.CASE_STUDIES}>
                                {chunks[0]}
                            </SPrefixLangLink>
                        );
                    },
                },
            )}
            data={useNftData()}
            isDescriptionFormattedPreviously
        />
    );
};
