import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import BlockChainSvg from 'svgs/blog/blockchain.svg';

const path = 'nft-subpage.view-nft-projects-solution-section';
export const PERKS = [
    {
        title: `${path}.transparency.title`,
        text: `${path}.transparency.desc`,
    },
    {
        title: `${path}.solutions.title`,
        text: `${path}.solutions.desc`,
    },
    {
        title: `${path}.technologies.title`,
        text: `${path}.technologies.desc`,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.BLOCKCHAIN_WEB3],
    button: {
        to: `${PATHS.BLOG}/${PATHS.BLOCKCHAIN}`,
        label: 'nft-subpage.footer.button',
    },
    categoryBanner: {
        title: 'nft-subpage.footer.title',
        description: 'nft-subpage.footer.description',
        svg: BlockChainSvg,
    },
};
