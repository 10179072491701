import React from 'react';
import { useIntl } from 'react-intl';
import { ContentRows } from 'modules/content-rows';
import Board from 'svgs/nft/board-for-painting.svg';

export const QualitySection = () => {
    const { formatMessage } = useIntl();

    return (
        <ContentRows
            columnsData={[
                {
                    title: 'nft-subpage.solveYourBusiness',
                    descriptions: [
                        formatMessage({
                            id: 'nft-subpage.ourTeamGathers',
                        }),
                    ],
                    img: <Board height="100%" width="100%" />,
                },
            ]}
        />
    );
};
