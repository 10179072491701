import React from 'react';
import InstantPayments from 'svgs/icons/ic-empty-wallet-time.svg';
import Decentralization from 'svgs/icons/ic-scan-barcode.svg';
import Security from 'svgs/icons/ic-shield-security.svg';
import Transparency from 'svgs/icons/ic-task.svg';

export const BLOCKCHAIN_DATA = [
    {
        icon: <Security width={42} height={42} />,
        text: 'nft-subpage.security',
        description: 'nft-subpage.blockchainTechnology',
    },
    {
        icon: <Decentralization width={42} height={42} />,
        text: 'nft-subpage.decentralization',
        description: 'nft-subpage.decentralizationEnsures',
    },
    {
        icon: <Transparency width={42} height={42} />,
        text: 'nft-subpage.transparency',
        description: 'nft-subpage.blockchainNetwork',
    },
    {
        icon: <InstantPayments width={42} height={42} />,
        text: 'nft-subpage.instantPayments',
        description: 'nft-subpage.blockchainTechnologyLets',
    },
];
