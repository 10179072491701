import React from 'react';
import { useIntl } from 'react-intl';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BadgesSection } from 'modules/badges-section';
import { StyledLink } from 'modules/page-footer/components/styled-link';

export const NftBadgesSection = () => {
    const { formatMessage } = useIntl();

    const images = useStaticQuery(graphql`
        {
            nftMarketplace: file(
                relativePath: {
                    eq: "top-it-companies-badges/nft-marketplace.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <BadgesSection>
            <StyledLink
                href={EXTERNAL_REDIRECTS.TOP_IT_COMPANIES_NFT_MARKETPLACE}
                ariaLabel={formatMessage({
                    id: 'nft-subpage.badges.nftMarket.alt',
                })}
                title={formatMessage({
                    id: 'nft-subpage.badges.nftMarket.title',
                })}
            >
                <GatsbyImage
                    image={
                        images.nftMarketplace.childImageSharp.gatsbyImageData
                    }
                    alt={formatMessage({
                        id: 'nft-subpage.badges.nftMarket.alt',
                    })}
                />
            </StyledLink>
        </BadgesSection>
    );
};
