import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CaseStudiesSection } from 'views/case-studies/case-studies-section';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { PATHS } from 'constants/paths';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { arrayOf, object, shape, string } from 'prop-types';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
`;

const STextWrapper = styled.div`
    text-align: center;
    margin: 0 auto 3.75rem;
`;

const SParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
    max-width: 60rem;
`;

const SButton = styled(Button)`
    margin-top: 4.375rem;
`;

export const SeeAllCaseStudiesSection = ({
    title,
    description,
    data,
    isDescriptionFormattedPreviously,
}) => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer>
            <STextWrapper>
                <HeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={title} />
                </HeaderSecond>
                <SParagraph>
                    {isDescriptionFormattedPreviously ? (
                        description
                    ) : (
                        <FormattedMessage id={description} />
                    )}
                </SParagraph>
            </STextWrapper>
            <CaseStudiesSection
                projectRangeFrom={0}
                projectRangeTo={3}
                as="div"
                margin={false}
                data={data}
            />
            <SButton to={PATHS.CASE_STUDIES} component="gatsby-link">
                <FormattedMessage id="general.seeAllCaseStudies" />
            </SButton>
        </SContainer>
    );
};

SeeAllCaseStudiesSection.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    data: arrayOf(
        shape({
            title: string,
            tags: arrayOf(string),
            image: object,
            to: string,
        }),
    ),
};

SeeAllCaseStudiesSection.defaultProptypes = {
    data: [],
};
