import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { PATHS } from 'constants/paths';
import { graphql, Link, useStaticQuery } from 'gatsby';
import NftCards from 'svgs/nft/nft-cards.svg';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

const SExternalLink = styled(Link)`
    color: inherit;
    text-decoration: underline;
`;

export const useNftServices = () => {
    const { formatMessage } = useIntl();

    const images = useStaticQuery(graphql`
        {
            smartContracts: file(
                relativePath: { eq: "nft/smart-contracts.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            businessDriven: file(
                relativePath: { eq: "nft/business-driven.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'nft-subpage.nftMarketplaces',
            descriptions: [
                formatMessage(
                    {
                        id: 'nft-subpage.exploreTheGrowingWorld',
                    },
                    {
                        link: (chunks) => {
                            return (
                                <SExternalLink
                                    href={`https://themobilereality.com/${PATHS.BLOG}/web3/nft-marketplace-development`}
                                >
                                    {chunks[0]}
                                </SExternalLink>
                            );
                        },
                    },
                ),
            ],
            img: <NftCards height="100%" width="100%" />,
            isReversed: true,
        },
        {
            title: 'nft-subpage.smartContractsDevelopment',
            descriptions: [
                formatMessage(
                    {
                        id: 'nft-subpage.transformYourBusiness',
                    },
                    {
                        link: (chunks) => {
                            return (
                                <SPrefixLangLink to={PATHS.CASE_STUDIES}>
                                    {chunks[0]}
                                </SPrefixLangLink>
                            );
                        },
                    },
                ),
            ],
            img: images.smartContracts,
            alt: formatMessage({
                id: 'nft-subpage.smartContractsDevelopmentAlt',
            }),
        },
        {
            title: 'nft-subpage.businessDrivenDevelopment',
            descriptions: [
                formatMessage(
                    {
                        id: 'nft-subpage.achieveYourBusiness',
                    },
                    {
                        link: (chunks) => {
                            return (
                                <SPrefixLangLink
                                    to={PATHS.SOFTWARE_DEVELOPMENT_PROCESS}
                                >
                                    {chunks[0]}
                                </SPrefixLangLink>
                            );
                        },
                    },
                ),
            ],
            img: images.businessDriven,
            alt: formatMessage({
                id: 'nft-subpage.businessDrivenDevelopmentAlt',
            }),
            isReversed: true,
        },
    ];
};
