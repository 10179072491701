import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { useNftServices } from 'views/nft/we-specialize-in-section/helpers';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { ContentRows } from 'modules/content-rows';

const STextWrapper = styled.div`
    text-align: center;
    max-width: 51rem;
    margin: 0 auto 6.25rem;
    color: var(--white-color);
`;

const SHeader = styled(HeaderSecond)`
    margin-bottom: 1.5rem;
`;

export const WeSpecializeInSection = () => {
    const nftServices = useNftServices();
    const title = 'nft-subpage.whatWeSpecializeIn';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <>
            <STextWrapper>
                <SHeader id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={title} />
                </SHeader>
                <Paragraph>
                    <FormattedMessage id="nft-subpage.unlockYourBusiness" />
                </Paragraph>
            </STextWrapper>
            <ContentRows
                columnsData={nftServices}
                textColumnCss={css`
                    color: var(--white-color);
                `}
            />
        </>
    );
};
