import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

export const useNftData = () => {
    const images = useStaticQuery(graphql`
        {
            nft_reality: file(
                relativePath: { eq: "case-studies/nft-reality-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            musicNftMarketplace: file(
                relativePath: { eq: "case-studies/music-nft-marketplace.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'case-studies-page.nftReality',
            field: 'case-studies-page.field.nftBlockchain',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.SMART_CONTRACT_DEVELOPMENT,
                CASE_STUDIES_TAG.NFT_DEVELOPMENT,
            ],
            image: images.nft_reality.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.NFT_REALITY}`,
        },
        {
            id: 'musicNftMarketplace',
            title: 'case-studies-page.musicNftMarketplace',
            field: 'case-studies-page.field.nftBlockchain',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.SMART_CONTRACT_DEVELOPMENT,
                CASE_STUDIES_TAG.NFT_DEVELOPMENT,
            ],
            image: images.musicNftMarketplace.childImageSharp.gatsbyImageData,
            to: null,
        },
    ];
};
